import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Typography,
    Pagination,
    Button,
    Menu,
    MenuItem,
    Stack,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { tofieds } from "functions/Functions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../../../css/style_ib.scss";

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const TableIB = (props) => {

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = React.useState(config);

        const sortedItems = React.useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(props.body);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        // console.log(sortConfig.direction);
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [id, setId] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [lastname, setLastname] = React.useState(null);
    const history = useHistory();

    const handleClickMenu = (event, id, name, lastname) => {
        setAnchorEl(event.currentTarget);
        setId(id);
        setName(name);
        setLastname(lastname);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const openPort = (id, name, lastname) => {
        // history.push(`/iblist/port/${id}`);
        history.push({
            pathname: `/iblist/port/${id}`,
            state: { username: `${name} ${lastname}` },
        });
    };

    return (
        <Grid className="table-btn">
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.head.map((el, i) => {
                                return (

                                    <>
                                        {el.id === 'my_lot' || el.id === 'net_deposit_team' ?
                                            <TableCell key={i} align={el.align || "left"} sx={{ cursor: "pointer" }} onClick={() => requestSort(el.id)}>
                                                {/* <Typography noWrap display="flex" alignItems="center">
                                                    {el.label}
                                                    <button className={getClassNamesFor(el.id)} />
                                                </Typography> */}
                                                <Typography noWrap display="flex" alignItems="center">
                                                    {el.label}
                                                    {getClassNamesFor(el.id) === 'ascending' ?
                                                        <ArrowDropUpIcon />
                                                        :
                                                        getClassNamesFor(el.id) === 'descending' ?
                                                            <ArrowDropDownIcon />
                                                            :
                                                            <SwapVertIcon />
                                                    }
                                                </Typography>
                                            </TableCell>
                                            :
                                            <TableCell key={i} align={el.align || "left"} >
                                                <Typography noWrap>{el.label}</Typography>
                                            </TableCell>
                                        }
                                    </>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((el, i) => {
                            return (
                                <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell >
                                        {/*props.profile.is_ib === 1 &&*/ el.count_team > 0 ?
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070", cursor: "pointer" }} onClick={() => props.onClickNetworkChild(el.user_id, el.name, el.lastname)}>
                                                {el.name} {el.lastname} ({el.count_team})
                                            </Typography>
                                            :
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }} >
                                                {el.name} {el.lastname} ({el.count_team})
                                            </Typography>
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.email}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.my_lot) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.commission) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.deposit) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.withdraw) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(Number(el.net_deposit)) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.net_deposit_team) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.team_deposit) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.team_lot) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {tofieds(el.agent_fee) || "0.00"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container alignContent="center">
                                            {el.verify_doc === 1 ? (
                                                <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#82C729" }}>
                                                    <Typography noWrap>Verify</Typography>
                                                </Grid>
                                            ) : el.verify_doc === 2 ? (
                                                <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#FF0000" }}>
                                                    <Typography noWrap>Unverify</Typography>
                                                </Grid>
                                            ) : el.verify_doc === 3 ? (
                                                <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "red" }}>
                                                    <Typography noWrap>Rejected</Typography>
                                                </Grid>
                                            ) : el.verify_doc === 4 ? (
                                                <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#002BB7" }}>
                                                    <Typography noWrap>Pending</Typography>
                                                </Grid>
                                            ) : (
                                                <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#FF0000" }}>
                                                    <Typography noWrap>Error</Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container justifyContent={"flex-start"}>
                                            <Button aria-controls="simple-menu" aria-haspopup="true"
                                                onClick={(event) => handleClickMenu(event, el.user_id, el.name, el.lastname)}
                                            >
                                                <MoreVertIcon />
                                            </Button>
                                            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                                <MenuItem
                                                    onClick={() => {
                                                        handleCloseMenu();
                                                        openPort(id, name, lastname);
                                                        // handleRoute(`/iblist/port`, el)
                                                    }}
                                                >
                                                    พอร์ตลูกค้า
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 320,
    },
    fontActive: {
        fontSize: 15,
        color: "#fff",
        padding: 8,
        borderRadius: 10,
    },
});
export default TableIB;
