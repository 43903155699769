import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, CardMedia, Radio, FormControlLabel, Button, Container, Stack } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import { textFiled } from "../../../functions/Static";
import imageIdcard from "../../../assets/images/image-idcard.png";
import active from "../../../assets/logo/active.png";
import driveFront from "../../../assets/images/drive-front.png";
import driveBack from "../../../assets/images/drive-back.png";
import passPort from "../../../assets/images/passport.png";
import book_bank from "../../../assets/logo/bookbank.png";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { ip } from "service";
import { GridTextField } from "components";
import { createMuiTheme } from "@material-ui/core/styles";
import '../../../css/index.scss'
import Swal from "sweetalert2";
const theme = createMuiTheme();

const TapAccount = (props) => {
     const { language, profile, handleChangeText, inputValue, checkInput, handleSubmit, data, menuItem, bank_check } = props;
     const classes = useStyles();
     const [state, setState] = React.useState({
          type: 1,
          step: 1,
          base64: null,
          base642: null,
          base643: null,
          file_front: null,
          file_back: null,
          book_bank: null,
          submit: false,
          dataImage: [],
     });

     const handleChange = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          state[name] = Number(value);
          setState({ ...state });
     };
     const subMit = async () => {
          let submitDoc = await props.submitAccount(state.book_bank);
          if (submitDoc) {
               setState({ ...state, submit: true });
          }
     };
     const uploadFile = (e, name, namefile) => {

          let file = e.target.files[0];

          if (e.target.files && e.target.files[0]) {
               if (file.size < 10485760) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                         setState({ ...state, [name]: e.target.result });
                    };
                    reader.readAsDataURL(e.target.files[0]);

                    if (namefile === "book_bank") {
                         state.book_bank = e.target.files[0];
                    }
                    setState({ ...state });
               } else {
                    Swal.fire({
                         icon: "warning",
                         text: `อัฟโหลดไฟล์ได้ขนาดไม่เกิน 10 MB`,
                         showConfirmButton: true,
                    });
               }
          }

     };
     let image_doc = [];
     if (profile.document_path) {
          image_doc = JSON.parse(profile.document_path);
     }


     return (
          <Grid>
               {/* {alert(ip + state.book_bank)} */}
               <Grid>
                    {profile.verify_bank !== 1 &&
                         (profile.verify_bank !== 4 && (
                              <Typography className={classes.titleStyle}>
                                   {language === "th" ? "โปรดสแกนและอัปโหลดเอกสารที่ต้องการด้านล่างนี้" : "PLEASE SCAN AND UPLOAD THE DOCUMENT(S) REQUESTED BELOW."}
                              </Typography>
                         ))}
               </Grid>
               <Grid mt={5} container justifyContent="center" alignItems="center" rowSpacing={2}>
                    <Grid item xs={12}>
                         <Typography className={classes.fontStyleTitle}>{language === "th" ? "อัปโหลดสมุดบัญชี" : "Upload Book Bank"}</Typography>
                         {profile.verify_bank !== 1 || profile.verify_bank !== 4 ? (
                              <CardUpload
                                   name="base643"
                                   namefile="book_bank"
                                   uploadFile={uploadFile}
                                   base64={state.base643}
                                   submit={state.submit}
                                   img={profile.bank_path ? ip + profile.bank_path : book_bank}
                                   language={language}
                                   profile={profile}
                              />
                         ) : (
                              <CardUpload
                                   name="base643"
                                   namefile="book_bank"
                                   uploadFile={uploadFile}
                                   base64={state.base643}
                                   submit={state.submit}
                                   img={book_bank}
                                   language={language}
                                   profile={profile}
                              />
                         )}

                         <Grid item container justifyContent="center" xs={12} marginTop={2}>
                              <Grid item container spacing={1} xs={12} md={6}>
                                   <GridTextField data={data} state={inputValue} language={language} handleChange={handleChangeText} menuItem={menuItem} />
                              </Grid>
                         </Grid>
                    </Grid>
                    {profile.verify_bank !== 1 && profile.verify_bank !== 4 && (
                         <Grid item xs={12} mt={10}>
                              <Container maxWidth="sm">
                                   <Grid container textAlign="center" columnSpacing={1} rowSpacing={3} justifyContent="center">
                                        <Grid item xs={12} sm={6}>
                                             <Button variant="contained" style={{ backgroundColor: "#4589C6" }} className="btn-style" sx={{ maxWidth: 260 }} onClick={subMit}>
                                                  {language === "th" ? "ตกลง" : "Submit"}
                                             </Button>
                                        </Grid>
                                   </Grid>
                              </Container>
                         </Grid>
                    )}
               </Grid>
          </Grid>
     );
};

const CardUpload = (props) => {
     const { profile } = props;
     const classes = useStyles();

     const [fullImg, setFullImg] = React.useState(true)

     const fullScreenImg = () => {
          setFullImg(!fullImg);
     }
     return (
          <Stack alignItems="center">
               {props.submit || profile.verify_bank === 4 ? (
                    <Grid container justifyContent="center">
                         <HourglassEmptyIcon color="primary" />
                         <Typography>{props.language === "th" ? "รอดำเนินการ..." : "waiting"}</Typography>
                    </Grid>
               ) : null}
               {(props.submit && props.confirm) || profile.verify_bank === 1 ? (
                    <CardMedia sx={{ objectFit: "contain", height: 26 }} component="img" alt="imageIdcard" image={active} />
               ) : null}
               <Grid sx={{ background: "#f8f8f8", p: 3, width: "auto", height: "auto", mt: 1 }}>
                    {/* <CardMedia className={classes.imgStyle} component="img" alt="imageIdcard" image={props.base64 ? props.base64 : props.img} /> */}
                    {fullImg &&
                         <CardMedia onClick={fullScreenImg} className="zoom-out" component="img" alt="imageIdcard" image={props.base64 ? props.base64 : props.img} />
                    }
                    {!fullImg &&
                         <>
                              <Grid display="flex" justifyContent="flex-end">
                                   <Button onClick={fullScreenImg}>X</Button>
                              </Grid>
                              <Grid className="full-img-modal-modal-content animate-zoom">
                                   <CardMedia component="img" alt="imageIdcard" image={props.base64 ? props.base64 : props.img} />
                              </Grid>
                         </>
                    }
               </Grid>
               {/* {props.submit || (profile.verify_bank !== 1 && profile.verify_bank !== 4) ? ( */}
               {(profile.verify_bank !== 1 && profile.verify_bank !== 4) ? (
                    <Stack alignItems="center">
                         <label htmlFor={props.name} style={{ width: 200 }}>
                              <Input
                                   // accept="image/*"
                                   accept="image/png, image/jpeg"
                                   id={props.name}
                                   type="file"
                                   onChange={(e) => props.uploadFile(e, props.name, props.namefile)}
                              />
                              <Button variant="contained" style={{ backgroundColor: "#4589C6" }} component="span" className="btn-style" sx={{ mt: 2, maxWidth: 200 }}>
                                   {props.language === "th" ? "เลือกไฟล์" : "Choose File"}
                              </Button>
                         </label>
                    </Stack>
               ) : null}
          </Stack>
     );
};
const Input = styled("input")({
     display: "none",
});
const useStyles = makeStyles(() => ({
     titleStyle: {
          fontSize: 20,
          color: "#000",
          textAlign: "center",
          // [theme.breakpoints.up("sm")]: {
          //      // fontSize: 32,
          // },
     },
     borderSolid: {
          textAlign: "center",
          width: 50,
          border: "solid 1px #4589C6",
          background: "borderSolid",
          [theme.breakpoints.up("md")]: {
               width: 100,
               border: "solid 2px #4589C6",
          },
     },
     imgStyle: {
          objectFit: "contain",
          height: 209,
          // [theme.breakpoints.up("md")]: {
          //      height: 140,
          // },
     },
     fontStyleTitle: {
          fontSize: 17,
          color: "#000",
          textAlign: "center",
          marginTop: 15,
          fontWeight: 600,
          [theme.breakpoints.up("md")]: {
               fontSize: 28,
          },
     },
     fontStyleDetail: {
          fontSize: 12,
          color: "#676767",
          textAlign: "center",
          marginTop: 10,
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
}));
export default withRouter(TapAccount);
