import React, { useState, forwardRef } from "react";
import { withRouter, NavLink as RoutLink, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Card, Button, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const Trader = (props) => {
     // const classes = useStyles();
     const { language, onClickDeposit, onClickWithdraw, handleChangeText, props_state, handleOpen } = props;
     const [hover, setHover] = useState(false);
     const [index, setIndex] = useState();
     const classes = useStyles();
     const [state, setState] = React.useState({
          card_data: [],
          mt4_id: null,
          open: false,
          obj: {},
          leverage: null,
          leverage_select: null,
          balance: 0,
     });
     React.useEffect(() => {
          const setData = () => {
               let { data } = props;
               setState({ card_data: data });
          };
          setData();
     }, [setState, props]);

     const [anchorEl, setAnchorEl] = React.useState(null);

     const CustomRouterLink = forwardRef((props, ref) => (
          <Grid ref={ref} style={{ flexGrow: 1 }}>
               <RoutLink {...props} />
          </Grid>
     ));

     const openPassword = (i, ind, name) => {
          state.card_data[i].arrDetail[ind][name] = !state.card_data[i].arrDetail[ind][name];
          setState({ card_data: state.card_data });
     };
     const openEdit = (i, ind) => {
          state.card_data[i].arrDetail[ind].edit = !state.card_data[i].arrDetail[ind].edit;
          setState({ card_data: state.card_data });
     };
     const onChange = (i, ind, value) => {
          state.card_data[i].arrDetail[ind].value = value;
          setState({ card_data: state.card_data });
     };
     const onMouseEnter = (i) => {
          setHover(true);
          setIndex(i);
     };
     const onMouseLeave = (i) => {
          setHover(false);
          setIndex(i);
     };

     const handleClickMenu = (event, mt4_id, leverage, leverage_select, user_mt4_id, balance, is_cent) => {
          setState({ ...state, mt4_id: mt4_id, leverage: leverage, leverage_select: leverage_select, user_mt4_id: user_mt4_id, balance, is_cent });
          setAnchorEl(event.currentTarget);
     };

     const handleCloseMenu = () => {
          setAnchorEl(null);
     };

     // const handleOpen = (el, text, type) => setState({ ...state, open: true, obj: { ...el, text, available_wallet: "$2,760.20", type: type } });
     // const handleClose = () => setState({ ...state, open: false, obj: {} });
     // const onMouseEnter = (i) => {
     //      setHover(true);
     //      setIndex(i);
     // };
     // const onMouseLeave = (i) => {
     //      setHover(false);
     //      setIndex(i);
     // };
     return (
          <Grid container justifyContent="center" alignItems="center" mb={5}>
               <Grid item xs={12} mb={2}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? `บัญชีผู้เทรด${(props.type.includes("real") || props.type === "real") ? "จริง" : "เดโม่"} ${props.meta_type}` : `${(props.type.includes("real") || props.type === "real") ? "Real" : "Demo"} Trader Account ${props.meta_type}`}
                    </Typography>
               </Grid>
               <Grid item container justifyContent={"flex-end"} mb={2}>
                    <Button
                         variant="contained"
                         style={{ backgroundColor: "#4589C6" }}
                         className="btn-style"
                         sx={{ maxWidth: 200 }}
                         onClick={() => props.onClickAddAccount(`${props.type.includes("real") ? props.type : "demo"}`, props.meta_type)}
                    >
                         {language === "th" ? "+ เพิ่ม" : "+ Add"}
                    </Button>
               </Grid>
               <Grid item xs={12} container rowSpacing={3} columnSpacing={3}>
                    {state.card_data.map((e, i) => (
                         <Grid key={i} item xs={12} md={6} lg={4} className="zoom">
                              <Card p={3} sx={props.type.includes("real") ? { borderRadius: 2, minHeight: 320 } : { borderRadius: 2 }} onMouseEnter={() => onMouseEnter(i)} onMouseLeave={() => onMouseLeave(i)} >
                                   <Grid container justifyContent="center" alignItems="center" direction="row" className={classes.hederStyle}>
                                        <Typography sx={{ fontSize: 16, fontWeight: 600, mr: 1, zIndex: 1 }}>{`ACCOUNT NO: ` + e.mt4}</Typography>
                                        <Grid item container justifyContent={"flex-end"} position={"absolute"}>
                                             <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClickMenu(event, e.user_mt4_id, e.leverage, e.leverage_select, e.user_mt4_id, e.balance, e.is_cent)}>
                                                  <MoreVertIcon style={{ color: "#fff" }} />
                                             </Button>
                                             <Menu id="simple-menu" key={i} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                                  <MenuItem
                                                       onClick={() => {
                                                            handleCloseMenu();
                                                            props.onClickEdit('real', "trader", state.mt4_id);
                                                       }}
                                                  >
                                                       Change Trader Password
                                                  </MenuItem>
                                                  <MenuItem
                                                       onClick={() => {
                                                            handleCloseMenu();
                                                            props.onClickEdit('real', "invester", state.mt4_id);
                                                       }}
                                                  >
                                                       Change Investor Password
                                                  </MenuItem>
                                                  <MenuItem
                                                       onClick={() => {
                                                            handleCloseMenu();
                                                            props.onClickDelete(state.user_mt4_id);
                                                       }}
                                                  >
                                                       Delete Account
                                                  </MenuItem>
                                                  {/* {(props.type.includes("real") && state.balance < 10000) &&
                                                       <MenuItem
                                                            onClick={() => {
                                                                 handleCloseMenu();
                                                                 props.onClickChangeLeverage(state.mt4_id, state.leverage, state.leverage_select);
                                                            }}
                                                       >
                                                            Change Leverage
                                                       </MenuItem>
                                                  } */}
                                                  {state.is_cent ?
                                                       // (props.type.includes("real") && state.balance < 1000000) &&
                                                       (props.type.includes("real") && state.balance < 2000000) &&
                                                       <MenuItem
                                                            onClick={() => {
                                                                 handleCloseMenu();
                                                                 props.onClickChangeLeverage(state.mt4_id, state.leverage, state.leverage_select);
                                                            }}
                                                       >
                                                            Change Leverage
                                                       </MenuItem>
                                                       :
                                                       // (props.type.includes("real") && state.balance < 10000) &&
                                                       (props.type.includes("real") && state.balance < 20000) &&
                                                       <MenuItem
                                                            onClick={() => {
                                                                 handleCloseMenu();
                                                                 props.onClickChangeLeverage(state.mt4_id, state.leverage, state.leverage_select);
                                                            }}
                                                       >
                                                            Change Leverage
                                                       </MenuItem>
                                                  }
                                             </Menu>
                                        </Grid>
                                   </Grid>
                                   <Grid container rowSpacing={1} pt={2} pb={2}>
                                        <Grid item xs={12} container columnSpacing={2}>
                                             <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Balance :</Typography>
                                             </Grid>
                                             <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                  <Typography sx={{ fontSize: 14 }}> {Math.floor(parseFloat(e.balance) * 100) / 100 || "0.00"}</Typography>
                                             </Grid>
                                             <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Account Group :</Typography>
                                             </Grid>
                                             <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                  <Typography sx={{ fontSize: 14 }}> {e.account_type_name || "-"}</Typography>
                                             </Grid>
                                             {/* <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Cent :</Typography>
                                             </Grid>
                                             <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                  <Typography sx={{ fontSize: 14 }}> {e.is_cent ? "YES" : "NO"}</Typography>
                                             </Grid> */}
                                             {props.type.includes("demo") ?
                                                  <>
                                                       <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Leverage :</Typography>
                                                       </Grid>
                                                       <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                            <Typography sx={{ fontSize: 14 }}> {"1:" + e.leverage || "-"}</Typography>
                                                       </Grid>
                                                  </>
                                                  :
                                                  (e.is_cent ?
                                                       // (props.type.includes("real") && e.balance < 1000000) &&
                                                       (props.type.includes("real") && e.balance < 2000000) &&
                                                       <>
                                                            <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                                 <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Leverage :</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                                 <Typography sx={{ fontSize: 14 }}> {"1:" + e.leverage || "-"}</Typography>
                                                            </Grid>
                                                       </>
                                                       :
                                                       // (props.type.includes("real") && e.balance < 10000) &&
                                                       (props.type.includes("real") && e.balance < 20000) &&
                                                       <>
                                                            <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                                 <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Leverage :</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                                 <Typography sx={{ fontSize: 14 }}> {"1:" + e.leverage || "-"}</Typography>
                                                            </Grid>
                                                       </>
                                                  )
                                             }
                                        </Grid>
                                        {props.type.includes("real") && (
                                             <Grid container textAlign="center" columnSpacing={1} rowSpacing={2} mt={1}>
                                                  <Grid item xs={12}>
                                                       {e.welcom_bonus === 1 ? null : e.welcom_bonus === true ? null : (
                                                            <Button
                                                                 variant="contained"
                                                                 style={{ backgroundColor: "#4589C6" }}
                                                                 className={classes.buttonContain}
                                                                 onClick={() => handleOpen(e, language === "th" ? "ฝาก" : "Deposit", "deposit", e.user_mt4_id)}
                                                            >
                                                                 {/* Deposit */}
                                                                 {language === "th" ? "ฝากจากวอลเล็ต" : "Deposit from Wallet"}
                                                            </Button>
                                                       )}
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                       <Button
                                                            variant="outlined"
                                                            color="error"
                                                            className={classes.buttonOutline}
                                                            onClick={() => handleOpen(e, language === "th" ? "ถอน" : "Withdraw", "withdraw", e.user_mt4_id)}
                                                       >
                                                            {/* Withdraw */}
                                                            {language === "th" ? "ถอนเข้าวอลเล็ต" : "Withdraw to Wallet"}
                                                       </Button>
                                                  </Grid>
                                                  {e.account_type_id !== 5 &&
                                                       <Grid item xs={12}>
                                                            <Link style={{ textDecoration: 'none' }} className="link" to={{ pathname: `/financial`, state: e.mt4 }} >
                                                                 <Button
                                                                      variant="outlined"
                                                                      color="error"
                                                                      className={classes.buttonOutline}
                                                                 >
                                                                      {/* Deposit To Financial */}
                                                                      {language === "th" ? "ฝากจากบัญชีธนาคาร" : "Deposit from bank account"}
                                                                 </Button>
                                                            </Link>
                                                       </Grid>
                                                  }
                                             </Grid>
                                        )}
                                        {/* {e.arrDetail.map((el, ind) => (
                                             <Grid key={ind} item xs={12} container columnSpacing={2}>
                                                  <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                       <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{el.title}</Typography>
                                                  </Grid>
                                                  <Grid item xs={3} container justifyContent="flex-start" alignItems="center">
                                                       {el.name ? (
                                                            <input
                                                                 type={el[el.name] ? "password" : "text"}
                                                                 name={el.name}
                                                                 value={el.value}
                                                                 // style={{ border: "none", width: "-webkit-fill-available", color: "#fff" }}
                                                                 className={classes.inputStyle}
                                                                 onChange={(e) => onChange(i, ind, e.target.value)}
                                                                 disabled={el.edit}
                                                            />
                                                       ) : (
                                                            <Typography sx={{ fontSize: 14 }}>{el.value}</Typography>
                                                       )}
                                                  </Grid>
                                                  <Grid item xs={1} container justifyContent="center" alignItems="center">
                                                       {el.img && (
                                                            <CardActionArea sx={{ width: "fit-content" }} onClick={() => openPassword(i, ind, el.name)}>
                                                                 <CardMedia component="img" alt="eye" image={el.img} className={classes.imgEyeStyle} />
                                                            </CardActionArea>
                                                       )}
                                                  </Grid>
                                                  <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                                                       {el.edit !== null && (
                                                            <Link
                                                                 component="button"
                                                                 onClick={() => {
                                                                      openEdit(i, ind);
                                                                 }}
                                                            >
                                                                 Edit
                                                            </Link>
                                                       )}
                                                  </Grid>
                                             </Grid>
                                        ))} */}
                                   </Grid>
                              </Card>
                         </Grid>
                    ))}
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     hederStyle: {
          background: "#4589C6",
          color: "#fff",
          height: 60,
          position: "relative",
     },
     buttonOutline: {
          maxWidth: 280,
          fontSize: 14,
          width: "100%",
          borderRadius: 40,
          background: "#fff",
          color: "#4589C6",
          border: "1px solid #4589C6",
     },
     buttonContain: {
          maxWidth: 280,
          fontSize: 14,
          width: "100%",
          borderRadius: 40,
          background: "#4589C6",
          color: "#fff",
     },
     // hederStyleHover: {
     //      background: "#fff",
     //      color: "#fc0316",
     //      height: 60,
     // },
     // contentStyleHover: {
     //      background: "#fc0316",
     //      color: "#fff",
     // },
     // editStyle: {
     //      fontSize: 14,
     //      color: "#fc0316",
     // },
     // editStyleHover: {
     //      fontSize: 14,
     //      color: "#fff",
     //      borderBottom: "1px solid #fff",
     // },
     imgEyeStyle: {
          objectFit: "cover",
          height: 18,
          width: 18,
     },
     // imgEyeStyleHover: {
     //      objectFit: "cover",
     //      height: 18,
     //      width: 18,
     //      WebkitFilter: "brightness(0) invert(1)",
     // },
     inputStyle: {
          border: "none",
          width: "-webkit-fill-available",
     },
     // inputStyleHover: {
     //      border: "none",
     //      width: "-webkit-fill-available",
     //      color: "#fff",
     // },
}));
// const useStyles = makeStyles((theme) => ({
//      imgStyle: {
//           height: 230,
//           [theme.breakpoints.up("sm")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//           [theme.breakpoints.up("md")]: {
//                objectFit: "cover",
//                height: 340,
//           },
//           [theme.breakpoints.up("lg")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//      },
//      borderSolid: {
//           width: "100%",
//           border: "solid 1px #fc0316",
//           background: "#fc0316",
//           [theme.breakpoints.up("md")]: {
//                width: 100,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 24,
//           color: "#000",
//           marginTop: 15,
//           fontWeight: 600,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                marginTop: 0,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 13,
//           color: "#000",
//           textAlign: "justify",
//           marginTop: 24,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                fontSize: 18,
//           },
//      },
//      btnStyle: {
//           textAlign: "right",
//           marginTop: 3,
//           [theme.breakpoints.up("md")]: {
//                marginTop: 16,
//                textAlign: "left",
//           },
//      },
// }));
export default withRouter(Trader);
